import React from 'react'
import logoWebp from 'imagens/webp/logo56x56.webp'
import logoPng from 'imagens/others/logo56x56.png'
import cartWebp from 'imagens/webp/carrinho-karden450x375.webp'
import cartPng from 'imagens/others/carrinho-karden450x375.png'
import EmailSubscribeForm, { OnSubmitFunction } from 'components/Generic/EmailSubscribeForm'
import { useNotification } from 'contexts/notificationContext'

const SubscribePage = () => {
    document.title = 'Aulas Fertilizantes - Inscreva-se'

    const { displayNotification } = useNotification();

    const onSubmitFunctions: OnSubmitFunction[] = [
        { when: "success", execute: () => displayNotification("Inscrição confimada, nos vemos quinta!") },
        { when: "fail", execute: () => displayNotification("Verifique se já está cadastrado ou tente novamente", "error") }
    ]

    return (
        <main>
            <section className='pb-12 pt-6 bg-darkGreen text-white'>
                <div className='container'>
                    <div className='flex flex-col items-center text-center'>
                        <picture className='mb-4'>
                            <source srcSet={logoWebp} type="image/webp"/>
                            <source srcSet={logoWebp} type="image/png"/> 
                            <img src={logoPng} className='w-14 h-14' alt='Logo da escola Karden - uma letra "K" branca com uma pequena folha sobre ela'/>
                        </picture>
                        <h1 className='text-3xl font-bold mb-4'>Aula Gratuita sobre Loja Virtual</h1>
                        <h3 className='text-base mb-4'>Toda quinta-feira às 19h. Aprenda com a Karden e tenha sua Loja em solo fértil.</h3>
                        <div className='flex flex-col lg:flex-row rounded-xl bg-white text-black px-10 py-8'>
                            <div className='lg:order-1'>
                                <h2 className='text-3xl text-darkGreen font-bold mb-4'>INSCREVA-SE GRÁTIS!</h2>
                                <h4 className='text-base mb-4'>Aproveite as Aulas Fertilizantes de nossa Escola gratuitamente.</h4>
                                <EmailSubscribeForm onSubmitFunctions={onSubmitFunctions} subscriptionList="aulas-fertilizantes" submitText={"Quero Participar"}/>
                            </div>
                            <picture>
                                <source srcSet={cartPng} type="image/webp"/>
                                <source srcSet={cartWebp} type="image/png"/> 
                                <img src={cartPng} className='w-full max-w-xl mb-4' alt='Logo da escola Karden - uma letra "K" branca com uma pequena folha sobre ela'/>
                            </picture>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        
    )
    
}

export default SubscribePage;
import React from "react";
import { Anchor } from "components";

import "./index.css";

//TODO PROP TYPE ANUAL AND MENSAL
const PricingCard = () => {
  return (
    <div className="md:min-w-[400px] w-full rounded-2xl py-10 px-5 text-white bg-darkGreen">
      <h6 className="text-xl mb-5">anual</h6>
      <div className="flex justify-center">
        <h3 className="text-5xl text-white mb-1 relative sm:mt-0 mt-4 before:content-['12x_de'] before:text-xl before:absolute sm:before:right-[105%] sm:before:bottom-0 sm:before:translate-x-0 before:whitespace-nowrap before:bottom-full before:right-[50%] before:translate-x-1/2">
          499,90
        </h3>
      </div>
      <hr className="w-1/4 h-0.5 bg-lightGreen text-lightGreen border-lightGreen mb-5 mx-auto" />
      <ul className="list-disc list-inside">
        <li className="mb-4">Aulas Fertilizantes (Semanais Ao Vivo)</li>
        <li className="mb-4">Acesso aos 7 módulos do Método Karden</li>
        <li className="mb-4">Suporte em caso de dúvidas</li>
        <li className="mb-4">
          <span>Desconto de R$400/ano</span>
        </li>
        <li className="mb-4">
          <span>Quadros de Premiação por faturamento alcançado</span>
        </li>
        <li>
          <span>Acesso as Spring Classes com especialistas</span>
        </li>
        <li className="mb-4">
          <span>
            1 Consultoria/ano, faremos um Checkup de sua Loja Virtual e te
            entregaremos um Plano de Ação prático.
          </span>
        </li>
      </ul>
      <Anchor
        href="https://api.whatsapp.com/send?phone=5517991903768&text=Ol%C3%A1%2C%20estou%20interessado%20na%20Escola%20Karden%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!"
        color="lightGreen"
        xs="h-fit text-white px-8"
      >
        <span className="text-xl font-semibold">TESTAR 7 DIAS GRÁTIS</span>
      </Anchor>
    </div>
  );
};

export default PricingCard;

import React from 'react'
import logoWebp from 'imagens/webp/logo56x56.webp'
import logoPng from 'imagens/others/logo56x56.png'
import EmailSubscribeForm, { OnSubmitFunction } from 'components/Generic/EmailSubscribeForm'
import { useNavigate } from "react-router-dom";

const FreeConsultancySubscribePage = () => {
    document.title = 'Consultoria Gratuita - Inscreva-se'
    const navigate = useNavigate();

    const handleNavigateVideoPage = () => {
        navigate("/consultoria-gratuita/liberada")
    } 

    const onSubmitFunctions: OnSubmitFunction[] = [
        { when: "both", execute: handleNavigateVideoPage }
    ]

    return (
        <main>
            <section className='py-6 bg-black text-white'>
                <div className='container'>
                    <div className='flex justify-end mb-4'>
                        <picture>
                            <source srcSet={logoWebp} type="image/webp"/>
                            <source srcSet={logoWebp} type="image/png"/> 
                            <img src={logoPng} className='w-8 h-8 mr-4' alt='Logo da escola Karden - uma letra "K" branca com uma pequena folha sobre ela'/>
                        </picture>
                    </div>
                    <h2 className='text-xl font-bold mb-4 text-green uppercase'>consultoria grátis</h2>
                    <h1 className='text-3xl font-bold mb-4'>Objetivo: entender o problema e entregar um plano de ação prático para você vender mais!</h1>
                    <h3 className='text-xl mb-4 font-semibold text-green'>Ficamos felizes por seu interesse em nossa ajuda! :)</h3>
                    <div className='rounded-2xl border-8 box-border border-green p-4 mx-4 md:mx-12 lg:mx-28 xl:mx-48 2xl:mx-64'>
                        <h2 className='text-xl font-bold uppercase'>Quase lá!</h2>
                        <h2 className='text-xl font-bold mb-4 uppercase'>Inscreva-se abaixo:</h2>
                        <EmailSubscribeForm onSubmitFunctions={onSubmitFunctions} subscriptionList="consultoria-gratuita" submitText="ME INSCREVER" />
                    </div>
                </div>
            </section>
        </main>
        
    )
    
}

export default FreeConsultancySubscribePage;
import {
  AboutSection,
  ApplySection,
  ClientsSection,
  FeaturesSection,
  LandingSection,
} from './Sections';
import { Footer } from './Subcomponents';

type NewLPProps = {
  mainVideoHref: string;
};

export const NewLP = ({ mainVideoHref }: NewLPProps) => {
  document.title =
    'Escola Karden – Plantando e acelerando Lojas Virtuais no Brasil';

  return (
    <main className="montserrat">
      <LandingSection mainVideoHref={mainVideoHref} />
      <FeaturesSection />
      <ApplySection background="bg-pedro" />
      <ClientsSection />
      <AboutSection />
      <ApplySection
        paragraph="Separamos um número limitado de vagas por semana para auxiliar gratuitamente lojistas a estruturar e escalar seus e-commerces."
        background="bg-pedro"
      />
      <Footer />
    </main>
  );
};

import React from "react";
import PricingCard from "./PricingCard";
import paymentsWebp from "imagens/webp/meios-pagamentos.webp";
import paymentsPng from "imagens/others/meios-pagamentos.png";

const PricingSection = () => {
  return (
    <section id="pricing" className="py-12 bg-white text-darkGreen text-center">
      <div className="container">
        <h2 className="md:text-4xl sm:text-3xl text-2xl font-bold mb-4">
          Sua Loja Virtual em solo fértil
        </h2>
        <h5 className="md:text-xl text-base mb-6">
          Tenha acesso imediato ao conteúdo e leve sua loja para outro nível!
        </h5>
        <div className="flex md:flex-nowrap flex-wrap justify-evenly mb-10">
          <div className="xl:basis-5/12 md:basis-1/2 md:w-auto w-full sm:px-5 md:pt-0 pt-4">
            <PricingCard />
          </div>
        </div>
        <div className="flex items-center flex-col">
          <h5 className="text-xl mb-3">PAGAMENTO 100% SEGURO</h5>
          <picture className="md:w-3/12 w-64">
            <source srcSet={paymentsWebp} type="image/webp" />
            <source srcSet={paymentsPng} type="image/png" />
            <img
              src={paymentsPng}
              className="w-full"
              alt="Meios de pagamento da escola Karden: Mastercard, Visa, American Express, Diners Club, Elo, Hipercard, Hiper, Boleto"
              loading="lazy"
            />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;

import React from "react";
import kiwifyWebp from "imagens/webp/kiwify-karden.webp";
import kiwifyPng from "imagens/others/kiwify-karden.png";
import { Widget, Anchor } from "components";
import { Play, Flag, Search, Target } from "components/Icons";

const AboutSection = () => {
  return (
    <section className="py-12 bg-darkGreen text-white">
      <div className="container">
        <div className="flex md:flex-nowrap flex-wrap mb-12">
          <div className="md:basis-1/2 md:w-auto w-full md:order-1 order-2 xl:pt-32 lg:pt-24">
            <h2 className="md:text-4xl sm:text-3xl text-2xl font-semibold">
              ASSISTA QUANDO QUISER.
            </h2>
            <h2 className="md:text-4xl sm:text-3xl text-2xl font-semibold mb-4">
              DE ONDE QUISER.
            </h2>
            <p className="md:text-xl text-base mb-4">
              A plataforma funciona no computador e tem até aplicativo pra
              celular. As dinâmicas ficam embaixo das aulas pra você executar!
              Os alunos sabem bem onde entrar nas aulas ao vivo, mas também
              podem revisar a gravação quando querem.
            </p>
            <Anchor href="#pricing" xs="h-fit px-8">
              <span className="font-semibold text-xl">TESTAR GRÁTIS</span>
            </Anchor>
          </div>
          <div className="md:basis-1/2 md:w-auto w-full md:order-2 order-1 flex items-center">
            <picture>
              <source srcSet={kiwifyWebp} type="image/webp" />
              <source srcSet={kiwifyPng} type="image/png" />
              <img
                src={kiwifyPng}
                className="lg:w-10/12 md:w-11/12 w-full ml-auto"
                alt="Um computador e um celular lado a lado no site mostrando aulas da escola Karden"
                loading="lazy"
              />
            </picture>
          </div>
        </div>
        <h2 className="md:text-4xl sm:text-3xl text-2xl font-semibold mb-4 text-center">
          O MÉTODO KARDEN NÃO É UM CURSO!
        </h2>
        <div className="flex flex-wrap text-darkGreen justify-evenly">
          <div className="lg:basis-5/12 basis-1/2 px-2 lg:px-0 my-3">
            <Widget icon={<Play />} text="Aulas ao vivo (semanais)" />
          </div>
          <div className="lg:basis-5/12 basis-1/2 px-2 lg:px-0 my-3">
            <Widget
              icon={<Search title="Icone de uma lupa" />}
              text="Método validado na prática"
            />
          </div>
          <div className="lg:basis-5/12 basis-1/2 px-2 lg:px-0 my-3">
            <Widget
              icon={<Target title="Icone de um alvo" />}
              text="80 aulas dinâmicas e práticas"
            />
          </div>
          <div className="lg:basis-5/12 basis-1/2 px-2 lg:px-0 my-3">
            <Widget
              icon={<Flag title="Icone de uma bandeira" />}
              text="26 semanas de mentorias ao vivo"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

import { ApplyButton } from '../Subcomponents';

type ApplySectionProps = {
  paragraph?: string;
  background?: string;
};

export const ApplySection = ({
  paragraph = 'Separamos um número limitado de vagas por semana para auxiliar gratuitamente empresários a estruturar e escalar seus e-commerces.',
  background,
}: ApplySectionProps) => (
  <section
    className={`bg-black text-white text-center ${
      background
        ? `${background} bg-[#1D1D1D] bg-fixed bg-[length:400px_600px] sm:bg-[length:500px_750px] bg-no-repeat bg-bottom sm:bg-right-bottom`
        : ''
    }`}
  >
    <div className="backdrop-grayscale bg-[#1D1D1D] bg-opacity-60 flex flex-col items-center py-28 px-3 gap-10">
      <p className="text-2xl max-w-[1036px]">{paragraph}</p>
      <h2 className="uppercase text-3xl max-w-[746px]">
        aplique para conquistar a sua vaga{' '}
        <span className="text-green">apertando o botão abaixo</span>
      </h2>
      <ApplyButton />
    </div>
  </section>
);

import authorImg from 'imagens/webp/pedro-smile.webp';

export const AboutSection = () => (
  <section className="bg-black text-white flex flex-col items-center py-14 px-3">
    <h2 className="text-4xl">
      Um pouco mais sobre a <br />
      <span className="text-green">Karden e Pedro Borotto</span>
    </h2>
    <div className="flex flex-col items-center gap-7 py-10 md:flex-row">
      <div className="w-[350px] h-[350px] min-w-[350px] rounded-full overflow-hidden">
        {/* TODO ALT */}
        <img src={authorImg} alt="" />
      </div>
      <div className="flex flex-col self-stretch justify-between max-w-[548px]">
        <p>
          A Karden é uma aceleradora de e-commerces, que auxilia empresas e
          lojistas a atingirem resultados incontestáveis acima dos 6 dígitos
          mensais
        </p>
        <p>
          Pedro Borotto, com apenas 24 anos, conquistou um lugar de destaque no
          mercado de E-commerce ao alcançar resultados excepcionais. Iniciou
          como dono de loja virtual, onde após falhar duas vezes dentro do
          e-commerce, em seu terceiro projeto de fato conseguiu criar tração,
          onde ultrapassou os R$ 4M em menos de 3 anos.
        </p>
        <p>
          Atualmente, ele se dedica a compartilhar e alavancar o projeto de
          centenas de empresários por todo o Brasil que atuam e buscam crescer
          dentro do mercado de E-commerce.
        </p>
      </div>
    </div>
  </section>
);

import React, { ReactNode } from 'react'

interface WidgetProps {
    text: string
    icon: ReactNode
}

const Widget = ({ text, icon }: WidgetProps) => {
    return (
        <div className="bg-white rounded-xl flex md:flex-row flex-col md:justify-center justify-evenly h-40 w-full items-center">
            <div className="md:basis-1/6 flex justify-end">
                {icon}
            </div>
            <div className="md:basis-5/6 md:pb-0 pb-4 sm:block flex justify-center">
                <span className='xl:text-2xl lg:text-xl font-semibold md:ml-4 md:text-left text-center'>{text}</span>
            </div>
        </div>
    )
}

export default Widget;
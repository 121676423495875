import React from 'react'
import { FreeConsultancyVideoSection } from 'components'

const FreeConsultancyVideoPage = () => {
    document.title = 'Consultoria Gratuita - Liberada'
    return (
        <main className='bg-black'>
            <FreeConsultancyVideoSection />
            <div className='container'>
                <div className="bg-green text-white p-6">
                    <h3 className='text-xl uppercase font-bold mb-6 text-center'>Agora, é só aguardar! ;)</h3>
                    <h5 className='text-base uppercase font-bold text-left mb-8'>-Equipe Karden.</h5>
                    <h5 className='text-base text-left'>Escola Karden © 2022. Todos direitos reservados.</h5>
                </div>
            </div>
        </main>
    )
}

export default FreeConsultancyVideoPage;
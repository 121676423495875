import React from 'react'
import { LandingSection, SliderSection, AboutSection, PricingSection, WarrantySection, FaqSection } from 'components'

const LandingPage = () => {
    document.title = 'Escola Karden – Plantando e acelerando Lojas Virtuais no Brasil'
    return (
        <main>
            <LandingSection />
            <SliderSection />
            <AboutSection />
            <PricingSection />
            <WarrantySection />
            <FaqSection />
        </main>
        
    )
    
}

export default LandingPage;
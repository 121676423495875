import { Footer, LandingPage, Loading, NewLP } from 'components';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingProvider from './contexts/loadingContext';
import NotificationProvider from './contexts/notificationContext';

const SubscribePage = React.lazy(
  () => import('components/Templates/SubscribePage')
);
const FreeClassSubscribePage = React.lazy(
  () => import('components/Templates/FreeClassSubscribePage')
);
const FreeClassVideoPage = React.lazy(
  () => import('components/Templates/FreeClassVideoPage')
);
const FreeConsultancySubscribePage = React.lazy(
  () => import('components/Templates/FreeConsultancySubscribePage')
);
const FreeConsultancyVideoPage = React.lazy(
  () => import('components/Templates/FreeConsultancyVideoPage')
);
const StrategicSessionVideoPage = React.lazy(
  () => import('components/Templates/StrategicSessionVideoPage')
);
const FrameworkSubscribePage = React.lazy(
  () => import('components/Templates/FrameworkSubscribePage')
);
const FrameworkVideoPage = React.lazy(
  () => import('components/Templates/FrameworkVideoPage')
);

function App() {
  return (
    <LoadingProvider>
      <NotificationProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="temp"
              element={
                <NewLP mainVideoHref="https://player.vimeo.com/video/927266654?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
              }
            />
            <Route
              path="temp2"
              element={
                <NewLP mainVideoHref="https://player.vimeo.com/video/931062414?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
              }
            />
            <Route
              path="temp3"
              element={
                <NewLP mainVideoHref="https://player.vimeo.com/video/931065233?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" />
              }
            />
            <Route
              path="/inscreva-se"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <SubscribePage />
                </React.Suspense>
              }
            />
            <Route
              path="/aula-gratuita"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <FreeClassSubscribePage />
                </React.Suspense>
              }
            />
            <Route
              path="/aula-gratuita/liberada"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <FreeClassVideoPage />
                </React.Suspense>
              }
            />
            <Route
              path="/consultoria-gratuita"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <FreeConsultancySubscribePage />
                </React.Suspense>
              }
            />
            <Route
              path="/consultoria-gratuita/liberada"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <FreeConsultancyVideoPage />
                </React.Suspense>
              }
            />
            <Route
              path="/sessao-estrategica"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <StrategicSessionVideoPage />
                </React.Suspense>
              }
            />
            <Route
              path="/framework"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <FrameworkVideoPage />
                </React.Suspense>
              }
            />
            <Route
              path="/mini-treinamento"
              element={
                <React.Suspense fallback={<Loading open={true} />}>
                  <FrameworkSubscribePage />
                </React.Suspense>
              }
            />
          </Routes>
          <Footer />
        </BrowserRouter>
      </NotificationProvider>
    </LoadingProvider>
  );
}

export default App;

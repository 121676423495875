import React, { useEffect, useState } from 'react'
import {ReactComponent as HandFinger} from './hand-finger.svg'
import {ReactComponent as HandClick} from './hand-click.svg'

type HandClickingProps = {
    xs?: string
}

const HandClicking = ({ xs }: HandClickingProps) => {
    const [isClicking, setIsClicking] = useState(false)
    useEffect(() => {
        const interval = setInterval(() => setIsClicking(clicking => !clicking), 1000)
        return () => clearInterval(interval)
    }, [])
    return (
        <div className={`absolute right-5 top-5 -rotate-[30deg] text-white ${xs}`}>
            {isClicking ? <HandClick title='Icone de uma mão clicando' /> : <HandFinger title='Icone de uma mão apontando' />}
        </div>
    )
}

export default HandClicking
import {
  faChartLine,
  faChartPie,
  faCompass,
  faSquarePollVertical,
} from '@fortawesome/free-solid-svg-icons';
import { SpanText } from 'types/utils';
import { Card } from '../Subcomponents';

const cards: { spanText: SpanText; icon: typeof faCompass }[] = [
  {
    icon: faCompass,
    spanText: {
      text: [
        {
          content: 'Diagnóstico personalizado',
          style: 'font-bold',
        },
        {
          content: ' da sua operação com Identificação de Gargalos e desafios',
        },
      ],
    },
  },
  {
    icon: faChartPie,
    spanText: {
      text: [
        {
          content: 'Dimensionamento ideal',
          style: 'font-bold',
        },
        {
          content: ' dos seus processos para hoje e para os próximos meses',
        },
      ],
    },
  },
  {
    icon: faChartLine,
    spanText: {
      text: [
        {
          content: 'Plano de Ação',
          style: 'font-bold flex justify-center',
        },
        {
          content:
            ' Tenha os próximos passos para você melhorar a performance desses pilares de escala',
        },
      ],
    },
  },
  {
    icon: faSquarePollVertical,
    spanText: {
      text: [
        {
          content: 'Solução personalizada',
          style: 'font-bold flex justify-center',
        },
        {
          content:
            ' Receba Uma solução Personalizada, de acordo com seus desafios e objetivos, para te ajudar a ter mais resultado, de forma mais assertiva e em menos tempo',
        },
      ],
    },
  },
];

export const FeaturesSection = () => (
  <section className="flex flex-col items-center text-center py-16 px-3 gap-8">
    <h2 className="text-3xl max-w-[656px]">
      O <span className="font-bold">que você vai receber</span> da Consultoria
      Gratuita de E-commerce?
    </h2>
    <p className="antialiased max-w-[775px] text-xl">
      Caso você seja qualificado, através da sua aplicação de interesse, vamos
      entrar em contato para propor alguns{' '}
      <span className="font-bold">horários disponíveis</span> do nosso time de
      consultores para olhar a operação do seu negócio e identificar quais{' '}
      <span className="underline">
        desses pilares você poderá usar para alavancar seu faturamento:
      </span>
    </p>
    <div className="flex gap-5 flex-wrap justify-center">
      {cards.map(({ icon, spanText }) => (
        <Card key={spanText.text[0].content} content={spanText} icon={icon} />
      ))}
    </div>
  </section>
);

import React, { useRef, useState } from "react";
import { FreeClassVideoSection, Button } from "components";
import { useSearchParams } from "react-router-dom";
import ModalForm from "components/Templates/FreeClassVideoPage/ModalForm";

const FreeClassVideoPage = () => {
  document.title = "Aula Gratuita - Liberada";
  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [hasSubscribed, setHasSubscribed] = useState(false);

  const handleSubscribe = () => setHasSubscribed(true);

  const form = useRef(null);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  try {
    const query = searchParams.get("form");
    if (query) {
      form.current = JSON.parse(atob(query));
    }
  } catch (_) {}

  return (
    <main className="bg-black">
      {modalOpen && form.current && (
        <ModalForm
          form={form.current}
          handleClose={handleCloseModal}
          hasSubscribed={hasSubscribed}
          handleSubscribe={handleSubscribe}
        />
      )}
      <FreeClassVideoSection />
      <section className="py-2 text-white">
        <div className="container">
          {!hasSubscribed && form.current && (
            <div className="flex justify-center">
              <Button
                xs="rounded-2xl cursor-pointer my-3 pt-2 pb-3 px-4 text-white font-bold md:text-2xl text-lg"
                onClick={handleOpenModal}
              >
                AGENDAR CONSULTORIA GRATUITA
              </Button>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default FreeClassVideoPage;

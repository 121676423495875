export const Footer = () => (
  <footer className={`bg-black text-white py-20`}>
    <div className="container">
      <h4 className="text-center">
        Karden © {new Date().getFullYear()}. Todos direitos reservados.
      </h4>
      <h6 className="text-center text-sm">P V BOROTTO EDUCACAO</h6>
      <h6 className="text-center text-sm">CNPJ: 31.628.203/0001-50</h6>
    </div>
  </footer>
);

import React, { useEffect, useState } from "react";
import { FrameworkVideo, Anchor } from "components";

const FreeClassVideoPage = () => {
  document.title = "Framework - Liberada";

  const [canShowButton, setCanShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanShowButton(true);
    }, 1000 * 8 * 60);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="bg-black">
      <FrameworkVideo />
      <section className="py-2 text-white">
        <div className="container">
          {canShowButton && (
            <div className="flex justify-center">
              <Anchor
                href="https://api.whatsapp.com/send?phone=5511959032608&text=Ol%C3%A1,%20quero%20saber%20mais%20sobre%20a%20Mentoria%20Karden"
                rel="noopener noreferrer"
                xs="px-8 text-black hover:text-white mb-8"
              >
                <span className="text-xl">Saber mais sobre a Mentoria</span>
              </Anchor>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default FreeClassVideoPage;

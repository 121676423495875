import React, { useState } from "react";

import { default as SlickSlider } from "react-slick";
import SliderItem from "./item";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.css'

import Slider1 from 'imagens/others/slider/slider-1.jpg'
import Slider2 from 'imagens/others/slider/slider-2.jpg'
import Slider3 from 'imagens/others/slider/slider-3.jpg'
import Slider4 from 'imagens/others/slider/slider-4.jpg'
import Slider5 from 'imagens/others/slider/slider-5.jpg'
import Slider6 from 'imagens/others/slider/slider-6.jpg'
import Slider7 from 'imagens/others/slider/slider-7.jpg'
import Slider8 from 'imagens/others/slider/slider-8.jpg'
import Slider9 from 'imagens/others/slider/slider-9.jpg'
import Slider10 from 'imagens/others/slider/slider-10.jpg'
import { HandClicking } from "components/Icons";
import AnualLock from "../AnualLock";

const Slider = () => {
  const [showClickIcon, setShowClickIcon] = useState(true);

  const handleInteraction = () => {
    if (showClickIcon){
      setShowClickIcon(false)
    }
  }

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  return (
    <div>
        <SlickSlider {...settings} className="text-black">
          <div onClick={handleInteraction}>
            <SliderItem titles={["Módulo", "Zero"]} background={Slider1}>
              {showClickIcon && <HandClicking />}
              <p className='mb-5 font-light'>
                Os alunos com as lojas virtuais enraizadas que hoje mais desfrutam do tempo livre, com certeza, não pularam esse módulo.
              </p>
              <p className='mb-5 font-light'>
                Foco na colheita. Aqui fica claro como utilizar o Método Karden começando do zero ou profissionalizando lojas físicas, ecommerces, dropshipping, etc.
              </p>
              <p className='font-light'>
                Preparado para escolher seu terreno?
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Escolhendo", "terrenos"]} background={Slider2}>
              <p className='font-light'>
                Os alunos com as lojas virtuais enraizadas que hoje mais desfrutam do tempo livre, com certeza, não pularam esse módulo.
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Preparando", "o Solo"]} background={Slider3}>
              <p className='mb-5 font-light'>
                Antes de vender, o terreno escolhido precisa de cuidado. Vamos criar seu site juntos! 
              </p>
              <p className='mb-5 font-light'>
                Desde a identidade visual e verbal da loja até exemplos das melhores embalagens.
              </p>
              <p className='mb-5 font-light'>
                Sempre na prática! Vai uma lista de fornecedores aí?
              </p>
              <p className='font-light'>
                Agora vamos vender…
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Plantando", "sementes"]} background={Slider4}>
              <p className='mb-5 font-light'>
                Segura essa cesta, por favor:
              </p>
              <p className='mb-5 font-light'>
                Escopos de vendas validados em diversos segmentos, solucionar os gargalos na jornada do cliente, aumentar a margem de lucro líquida… ufa! Pesada, né?
              </p>
              <p className='font-light'>
                Melhor você iniciar seu plantio agora mesmo…
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Equipamentos", "fundamentais"]} background={Slider5}>
              <p className='mb-5 font-light'>
                Facebook ADS, Google ADS, Email Marketing e Google Analytics… você não dá conta sozinho de todas as vendas que vai fazer!
              </p>
              <p className='font-light'>
                Ainda bem que o Método Karden detalha as ferramentas necessárias para vender mais com menos dor de cabeça.
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Cultivando", "sua planta"]} background={Slider6}>
              <p className='mb-5 font-light'>
                  Falando em dor de cabeça, o Método Karden também está aqui para te auxiliar caso as coisas saiam um pouquinho do controle!
              </p>
              <p className='font-light'>
                  Uma simples mudança pode aumentar até 32% do seu faturamento com o mesmo esforço (ou até menos).
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Expandindo", "a Plantação"]} background={Slider7}>
              <p className='mb-5 font-light'>
                Mais dinheiro! O que fazer?
              </p>
              <p className='mb-5 font-light'>
                Não, não… nada disso que você tá pensando kkkkkkkk
              </p>
              <p className='font-light'>
                Vamos ajudar a sua loja acelerar seu crescimento de maneira saudável e lucrativa.
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Aulas", "fertilizantes"]} background={Slider8}>
              <p className='mb-5 font-light'>
                Quinta-feira às 19 horas.
              </p>
              <p className='mb-5 font-light'>
                Encontro da Karden marcado na agenda e dois despertadores ativados.
              </p>
              <p className='font-light'>
                Sabe aquele detalhe que você imagina que só você teve dúvidas? Vamos conversar sobre ele.
              </p>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Spring", "Classes"]} background={Slider9}>
              <p className='mb-5 font-light sm:block hidden'>
                Reservei somente para os alunos do Plano anual um piquenique gourmet com especialistas de diversas áreas pra bater um papo aprofundado:
              </p>
              <ul className="list-disc md:leading-5">
                <li>
                  Design no Ecommerce simples e profissional;
                </li>
                <li>
                  Criando uma Marca inteligente e efetiva;
                </li>
                <li>
                  Tudo o que você precisa saber sobre Empresas, Impostos e Notas fiscais;
                </li>
                <li>
                  Mercado Live e outros Marketplaces (Em breve);
                </li>
                <li>
                  Produção de Conteúdo para sua Loja Virtual (Em breve);
                </li>
                <li>
                  UX no Ecommerce (Em breve);
                </li>
                <li>
                  Liderança (Em breve);
                </li>
              </ul>
              <div className="anual-plan absolute bottom-10 left-1/2 -translate-x-1/2">
                <AnualLock />
              </div>
            </SliderItem>
          </div>
          <div onClick={handleInteraction}>
            <SliderItem titles={["Consultoria", "Individual"]} background={Slider10}>
              <p className='mb-5 font-light'>
                Nossos Alunos Anuais tem acesso à Consultoria Individual, iremos fazer uma analise completa de sua Loja Virtual e te dar um Plano de Ação prático para que sua loja evolua.
              </p>
              <div className="anual-plan absolute bottom-10 left-1/2 -translate-x-1/2">
                <AnualLock />
              </div>
            </SliderItem>
          </div>
        </SlickSlider>
    </div>
  );
}

export default Slider
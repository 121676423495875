import React, { ChangeEvent } from "react";

type RadioProps = {
  name: string;
  value: string;
  label?: string;
  handleRadioChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const Radio = ({ name, value, label, handleRadioChange }: RadioProps) => {
  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}
      <input
        onChange={handleRadioChange}
        name={name}
        type="radio"
        value={value}
        className="ml-2 mr-4"
      />
    </div>
  );
};

export default Radio;

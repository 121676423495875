import React, { ReactNode } from 'react'

type AccordionProps = {
    title: string
    details: ReactNode
}

const Accordion = ({ title, details }: AccordionProps) => {

    return (
        <details className='bg-lightGreen my-4'>
            <summary style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)' }} className='p-5 md:text-2xl text-lg cursor-pointer font-semibold'>
                <span>{title}</span>
            </summary>
            <div className='bg-semidarkGreen shadow-inner p-5 md:text-base text-sm'>
                {details}
            </div>  
        </details>
    )
}

export default Accordion
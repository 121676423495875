import React from 'react'

import { Lock } from 'components/Icons'

const AnualLock = () => {
    return (
        <div className='flex items-center' style={{ textShadow: '0px 0px 10px black, 0px 0px 15px black' }}>
            <Lock title='Icone de um cadeado' />
            <div>
                <p className="xl:text-sm lg:text-xs leading-tight ">Acesso Exclusivo</p>
                <p className="xl:text-xl lg:text-sm leading-tight font-semibold">Plano Anual</p>
            </div>
        </div>
    )
}

export default AnualLock
import React from 'react'
import { Accordion } from 'components'

const FaqSection = () => {
    return (
        <section className='py-12 text-white bg-darkGreen'>
            <div className='container'>
                <h2 className='md:text-4xl text-2xl text-center font-bold mb-10'>Perguntas frequentes, respostas na lata.</h2>
                <Accordion 
                    title="2. Como funciona a garantia de 7 dias para testar?" 
                    details={
                        <>
                            <p className='mb-4'>É simples, você assina a Escola e tem até 7 dias para ver se gosta…</p>
                            <p>Caso não esteja satisfeito, basta solicitar o reembolso dentro do prazo de 7 dias, que iremos te estornar o valor integral sem problema algum.</p>
                        </>
                    }
                />
                <Accordion 
                    title="3. É para iniciantes ou avançados?" 
                    details={
                        <p>Ambos. Os dois precisam estar em constante evolução.</p>
                    }
                />
                <Accordion 
                    title="4. Preciso ter um estoque grande pra começar?" 
                    details={
                        <p>De forma alguma! Mas, se um dia precisar, significa que você está expandindo a plantação. É positivo!</p>
                    }
                />
                <Accordion 
                    title="5. O que são as Aulas Fertilizantes?" 
                    details={
                        <>
                            <p className='mb-4'>São aulas semanais e ao vivo, sobre temas gerais ligados a Empreendedorismo.</p>
                            <p className='mb-4'>Serão aulas que assim como o nome sugere, irão acelerar o crescimento de sua Loja, e ao final da aula os alunos da Escola terão espaço para tirarem dúvidas, o que vai ajudar muito para esclarecer algum ponto que não tenha ficado tão claro.</p>
                            <p>Toda quinta-feira às 19h</p>
                        </>
                    }
                />
            </div>
        </section>
    )
}

export default FaqSection
import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

import logoWebp from "imagens/webp/logo56x56.webp";
import logoPng from "imagens/others/logo56x56.png";
import { Anchor } from "components";

const LandingSection = () => {
  return (
    <section className="py-12 text-white bg-darkGreen">
      <div className="container">
        <div className="flex md:mb-28 mb-10">
          <div className="basis-1/2">
            <picture>
              <source srcSet={logoWebp} type="image/webp" />
              <source srcSet={logoWebp} type="image/png" />
              <img
                src={logoPng}
                className="w-14 h-14"
                alt='Logo da escola Karden - uma letra "K" branca com uma pequena folha sobre ela'
              />
            </picture>
          </div>
          <div className="basis-1/2 flex justify-end items-center">
            <Anchor
              href="https://membros.escolakarden.com/"
              xs="h-fit md:w-1/4 w-1/2"
            >
              <span className="font-semibold">ENTRAR</span>
            </Anchor>
          </div>
        </div>
        <div className="flex md:flex-nowrap flex-wrap">
          <div className="md:basis-1/2 md:w-auto w-full md:order-1 order-2">
            <h2 className="text-green font-bold sm:text-2xl text-xl mb-4">
              Escola Karden
            </h2>
            <h1 className="font-bold md:text-4xl sm:text-3xl text-2xl leading-tight mb-4">
              CRIE SUA LOJA VIRTUAL EM SOLO FERTIL E VENDA TODO DIA
            </h1>
            <p className="md:text-xl text-base mb-4">
              Dentro da Karden, todos já estão{" "}
              <strong>multiplicando os frutos das suas lojas virtuais.</strong>{" "}
              Sem depender de terrenos alugados nem de investimentos excessivos!
            </p>
            <Anchor href="#pricing" xs="h-fit px-8">
              <span className="font-semibold text-xl">TESTAR GRÁTIS</span>
            </Anchor>
          </div>
          <div className="md:basis-1/2 md:w-auto w-full md:order-2 order-1 md:pb-0 pb-4">
            <LiteYouTubeEmbed
              poster="hqdefault"
              id="sD-j41qqGRM"
              title="Conheça a Escola Karden - 1ª Escola Aceleradora de E-commerce do Brasil"
              webp
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;

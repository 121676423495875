import React, { ReactNode, useState } from 'react'
import useProgressiveImage from 'hooks/progressiveImage'

import './item.css'

type SliderItemProps = {
    background: string,
    children: ReactNode,
    titles: string[]
}

const SliderItem = ({ background, children, titles }: SliderItemProps) => {
    const [showInfo, setShowInfo] = useState(false);

    const image = useProgressiveImage(background);

    const handleCardClick = () => {
        setShowInfo(show => !show)
    }
    
    return (
        <div onClick={handleCardClick} className={`slider-item rounded-xl mx-4 text-white text-left cursor-pointer bg-no-repeat bg-cover ${showInfo ? "active" : ""}`} style={{ backgroundImage: `url(${image ?? ""})`}}>
            <div className='p-10 h-full rounded-xl relative'>
                <hr style={{ boxShadow: '0px 0px 10px black' }} className='w-1/4 h-0.5 bg-white text-white border-white mb-5' />
                {titles.map((title, index) => <h3 key={title + index} style={{ textShadow: '0px 0px 10px black' }} className={`xl:text-4xl text-3xl font-bold ${index === titles.length-1 ? "mb-5" : ""}`}>{title}</h3>)}
                {children}
            </div>
        </div>
    )
}

export default SliderItem
import { ModalVideo } from '../Subcomponents/ModalVideo';

import anacetim from 'imagens/webp/anacetim.webp';
import brunobydash from 'imagens/webp/brunobydash.webp';
import leonardokrotare from 'imagens/webp/leonardokrotare.webp';
import ligialiss from 'imagens/webp/ligialiss.webp';
import pedrodplug from 'imagens/webp/pedrodplug.webp';
import thiagotrparts from 'imagens/webp/thiagotrparts.webp';

const clientCards = [
  {
    text: 'Ana - Cetim-tim',
    href: 'https://player.vimeo.com/video/982602979?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 982602979,
    image: anacetim,
  },
  {
    text: 'Pedro - DPLug',
    href: 'https://player.vimeo.com/video/982603510?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 982603510,
    image: pedrodplug,
  },
  {
    text: 'Thiago - TRParts',
    href: 'https://player.vimeo.com/video/982604004?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 982604004,
    image: thiagotrparts,
  },
  {
    text: 'Leonardo - Krotare',
    href: 'https://player.vimeo.com/video/982603756?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 982603756,
    image: leonardokrotare,
  },
  {
    text: 'Bruno - By Dash',
    href: 'https://player.vimeo.com/video/982603317?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 982603317,
    image: brunobydash,
  },
  {
    text: 'Ligia - Liss Aromas',
    href: 'https://player.vimeo.com/video/982603126?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    id: 982603126,
    image: ligialiss,
  },
];

export const ClientsSection = () => (
  <section className="bg-black text-white text-center flex flex-col items-center py-14 px-3 gap-16">
    <h2 className="text-4xl">
      O que alguns{' '}
      <span className="capitalize">
        clientes falam <span className="text-green">sobre nós</span>
      </span>
    </h2>
    <div className="flex gap-6 flex-wrap justify-center max-w-[1639px]">
      {clientCards.map((modalProps) => (
        <ModalVideo key={modalProps.text} {...modalProps} />
      ))}
    </div>
  </section>
);

import React from 'react'
import './index.css'

const Play = () => {
    return (
        <>
            <span className='sr-only'>Icone de Play</span>
            <i className="gg-play-button-o"></i>
        </>
    )
}

export default Play
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { SpanText } from 'types/utils';

type CardProps = {
  content?: SpanText;
  w?: string;
  h?: string;
  icon: FontAwesomeIconProps['icon'];
};

export const Card = ({
  content,
  w = 'w-[297px]',
  h = 'h-[314px]',
  icon,
}: CardProps) => {
  return (
    <div
      className={`flex flex-col ${w} ${h} bg-black text-white justify items-center box-border rounded-[30px] py-8 px-3 shadow-2xl`}
    >
      <FontAwesomeIcon icon={icon} className="text-green w-11 h-11" />
      {content && (
        <p className="m-auto">
          {content.text.map(({ content, style }) => (
            <span key={content} className={style}>
              {content}
            </span>
          ))}
        </p>
      )}
    </div>
  );
};

import Button from "components/Generic/Button";
import Radio from "components/Generic/Radio";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";

type ModalFormProps = {
  form: Record<string, string>;
  handleClose: VoidFunction;
  hasSubscribed: boolean;
  handleSubscribe: VoidFunction;
};

const ModalForm: React.FC<ModalFormProps> = ({
  form,
  handleClose,
  hasSubscribed,
  handleSubscribe,
}) => {
  const [radioState, setRadioState] = useState<Record<string, string>>({});
  const [step, setStep] = useState(0);

  const sendingRequest = useRef<boolean>(false);

  const canUpdateStep = Object.keys(radioState).length > step;

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setRadioState({
      ...radioState,
      [name]: value,
    });
  };

  const incrementStep = () => {
    canUpdateStep && setStep(step + 1);
  };

  const shouldNotSubscribeToForm =
    radioState.store === "nao" ||
    radioState.importance === "pouco" ||
    radioState.invest === "0";

  useEffect(() => {
    if (step === 4 && !shouldNotSubscribeToForm && !sendingRequest.current) {
      sendingRequest.current = true;
      const body = {
        name: form.name + " [AULA]",
        email: form.email,
        whatsapp: form.whatsapp,
        subscription_list: "consultoria-gratuita",
      };
      fetch(
        "https://9mxyf4e4ce.execute-api.sa-east-1.amazonaws.com/prod/email_subscribe",
        { method: "post", body: JSON.stringify(body) }
      )
        .then((_) => {
          handleSubscribe();
        })
        .catch((_) => {
          handleSubscribe();
        });
    }
  }, [step, shouldNotSubscribeToForm, form, handleSubscribe]);

  return (
    <>
      <div
        className="w-screen h-screen bg-black bg-opacity-70 fixed left-0 top-0 z-[9]"
        onClick={() => {
          if (step === 4 && !shouldNotSubscribeToForm && !hasSubscribed) return;
          handleClose();
        }}
      ></div>
      <div className="fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 z-10 bg-white p-10 rounded-lg max-w-full w-[700px]">
        <div className="flex items-center flex-col text-center">
          {step < 4 && (
            <div className="flex justify-evenly w-24 mb-2">
              <div
                className={`w-4 h-4 ${
                  step >= 0 ? "bg-green" : ""
                } rounded-full border-black border`}
              ></div>
              <div
                className={`w-4 h-4 ${
                  step >= 1 ? "bg-green" : ""
                } rounded-full border-black border`}
              ></div>
              <div
                className={`w-4 h-4 ${
                  step >= 2 ? "bg-green" : ""
                } rounded-full border-black border`}
              ></div>
              <div
                className={`w-4 h-4 ${
                  step >= 3 ? "bg-green" : ""
                } rounded-full border-black border`}
              ></div>
            </div>
          )}
          {step === 0 && (
            <>
              <p>Você tem uma loja virtual?</p>
              <div className="flex justify-evenly">
                <Radio
                  label="Sim"
                  name="store"
                  value="sim"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="Não"
                  name="store"
                  value="nao"
                  handleRadioChange={handleRadioChange}
                />
              </div>
            </>
          )}
          {step === 1 && (
            <>
              <p>Como você lida com o assunto Marketing e Vendas? 📊</p>
              <div className="flex justify-evenly ">
                <Radio
                  label="Me ajuda"
                  name="sell"
                  value="ajuda"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="Entendo um pouco"
                  name="sell"
                  value="entendo"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="Tenho conhecimento"
                  name="sell"
                  value="conheco"
                  handleRadioChange={handleRadioChange}
                />
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <p>Quão importante é para você aumentar seu faturamento? 🎯</p>
              <div className="flex justify-evenly">
                <Radio
                  label="Pouco"
                  name="importance"
                  value="pouco"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="Médio"
                  name="importance"
                  value="medio"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="Muito"
                  name="importance"
                  value="muito"
                  handleRadioChange={handleRadioChange}
                />
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <p>
                Quanto você está disposto(a) a investir POR MÊS para aumentar
                seu faturamento? 🚀
              </p>
              <p>(Marketing, Divulgações e Conhecimento)</p>
              <div className="flex justify-evenly">
                <Radio
                  label="R$0 (zero)"
                  name="invest"
                  value="0"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="R$40 - R$99"
                  name="invest"
                  value="99"
                  handleRadioChange={handleRadioChange}
                />
                <Radio
                  label="R$100 - R$1000"
                  name="invest"
                  value="1000"
                  handleRadioChange={handleRadioChange}
                />
              </div>
            </>
          )}
          {step < 4 && (
            <Button
              xs="mt-4 w-10 h-10 rounded-full border border-black"
              onClick={incrementStep}
              disabled={!canUpdateStep}
            >
              &gt;
            </Button>
          )}
          {step === 4 && (
            <>
              {shouldNotSubscribeToForm ? (
                <div className="bg-strongRed bg-opacity-50 p-2">
                  <p>
                    Infelizmente você não está apto a participar de nossa
                    consultoria individual gratuita
                  </p>
                </div>
              ) : (
                <div className="bg-green p-2">
                  <p>Uhuuuul! Você foi aprovado(a) 🏆</p>
                  <p>
                    Algum de nosso Especialistas vai te chamar em seu WhatsApp
                    para agendar a Consultoria! 📆
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ModalForm;

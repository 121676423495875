import React from "react";
import logoWebp from "imagens/webp/logo56x56.webp";
import logoPng from "imagens/others/logo56x56.png";
import EmailSubscribeForm, {
  OnSubmitFunction,
} from "components/Generic/EmailSubscribeForm";
import { useNavigate } from "react-router-dom";
import { FieldValues } from "react-hook-form";

const FreeClassSubscribePage = () => {
  document.title = "Aula Gratuita - Inscreva-se";
  const navigate = useNavigate();

  const handleNavigateVideoPage = (data: FieldValues) => {
    const formData = {
      name: data.nome,
      email: data.email,
      whatsapp: Number(data.whatsapp.trim()),
    };
    navigate(`/aula-gratuita/liberada?form=${btoa(JSON.stringify(formData))}`);
  };

  const onSubmitFunctions: OnSubmitFunction[] = [
    { when: "both", execute: handleNavigateVideoPage },
  ];

  return (
    <main>
      <section className="py-6 bg-black text-white">
        <div className="container">
          <div className="flex justify-end mb-4">
            <picture>
              <source srcSet={logoWebp} type="image/webp" />
              <source srcSet={logoWebp} type="image/png" />
              <img
                src={logoPng}
                className="w-8 h-8 mr-4"
                alt='Logo da escola Karden - uma letra "K" branca com uma pequena folha sobre ela'
              />
            </picture>
          </div>
          <h2 className="text-xl font-bold mb-4 text-green">
            AULA GRATUITA EXCLUSIVA
          </h2>
          <h1 className="text-3xl font-bold mb-4">
            Como construir uma Loja Virtual que vende 10k, 30k, 50k ou até
            R$100.000/mês.
          </h1>
          <h3 className="text-xl mb-4 font-semibold text-green">
            Mesmo sem saber o que vender, com baixo investimento e poucos
            seguidores.
          </h3>
          <div className="rounded-2xl border-8 box-border border-green p-4 mx-4 md:mx-12 lg:mx-28 xl:mx-48 2xl:mx-64">
            <h2 className="text-xl font-bold mb-4">
              INSCREVA-SE ABAIXO E ASSISTA A AULA GRATUITA:
            </h2>
            <EmailSubscribeForm
              onSubmitFunctions={onSubmitFunctions}
              subscriptionList="aula-gratuita"
              submitText="QUERO ASSISTIR"
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default FreeClassSubscribePage;

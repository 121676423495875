import { faPlay, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Player from '@vimeo/player';
import { useEffect, useRef, useState } from 'react';

type ModalVideoProps = {
  href: string;
  text: string;
  id: number;
  image: string;
};

export const ModalVideo = ({ text, id, image }: ModalVideoProps) => {
  const [open, setOpen] = useState(false);

  const playerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (playerRef.current !== null) {
      let player = new Player(playerRef.current, { id });

      const iframe = playerRef.current.querySelector('iframe');
      if (iframe) {
        iframe.style.position = 'absolute';
        iframe.style.width = '100%';
        iframe.style.height = '100%';
      }

      const body = document.querySelector('body');
      if (open && body) {
        body.style.overflow = 'hidden';
        return;
      }

      if (!open) {
        player.pause();
        if (body) {
          body.style.overflow = 'auto';
        }
      }
    }
  }, [id, open]);

  return (
    <>
      <div className="flex flex-col gap-4 items-center justify-center">
        <button onClick={() => setOpen(true)} className="relative">
          <img src={image} alt={text} className="w-[270px] lg:w-[396px]" />
          <FontAwesomeIcon
            icon={faPlay}
            aria-hidden
            className="text-white w-[14px] h-4 bg-[#000] py-2 px-[21px] rounded absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
          <div
            aria-hidden
            className="bg-white opacity-0 hover:opacity-20 w-full h-full absolute top-0 le0s"
          ></div>
        </button>
        <span>{text}</span>
      </div>
      <div
        className={`${
          open ? 'animate-fadein' : 'hidden'
        } fixed top-0 right-0 bottom-0 left-0 bg-black bg-opacity-70 flex items-center justify-center p-10 z-10`}
        onClick={() => setOpen(false)}
      >
        <button
          className="absolute right-2 top-2"
          onClick={() => setOpen(false)}
          aria-label="Fechar"
        >
          <FontAwesomeIcon
            icon={faXmarkCircle}
            aria-hidden
            className="text-green w-8 h-8 hover:text-semidarkGreen"
          />
        </button>
        <div
          ref={playerRef}
          className="relative w-[calc(calc(100dvh-80px)/1.78125)] aspect-[472/839] max-h-full"
        ></div>
      </div>
    </>
  );
};

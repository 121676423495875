import React, { AnchorHTMLAttributes } from "react";

interface ButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  xs?: string;
  color?: "lightGreen" | "darkGreen";
}

const Anchor = ({
  xs,
  color = "lightGreen",
  children,
  ...rest
}: ButtonProps) => {
  return (
    <a
      className={`bg-${color} inline-block rounded-md text-center py-2 ${xs}`}
      style={{ textShadow: "0px 0px 5px rgb(0 0 0 / 30%)" }}
      {...rest}
    >
      {children}
    </a>
  );
};

export default Anchor;

import React, { createContext, useContext } from 'react'
import { useState } from 'react'
import { Loading } from 'components'
  
type LoadingContextProps = {
    setIsLoading: (isLoading: boolean) => void
}

const LoadingContext = createContext<LoadingContextProps>({
    setIsLoading: () => {
        // default value.
    }
})
  
  const LoadingProvider: React.FC = ({ children }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    return (
      <LoadingContext.Provider value={{ setIsLoading }}>
        <Loading
          open={isLoading}
        />
        {children}
      </LoadingContext.Provider>
    )
  }
  
  export default LoadingProvider
  
  export function useLoading(): LoadingContextProps {
    return useContext(LoadingContext)
  }
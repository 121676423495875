import React from "react";
import { Info } from "components/Icons";

export type NotificationProps = {
    text: string
    type: "success" | "error"
    open: boolean
    onClose: () => void
}

const Notification = ({ text, type, open, onClose }: NotificationProps) => {
    return (
        <>
            {open && (
                <div className="w-full fixed flex justify-center top-5 z-40">
                    <div className={`w-full lg:w-1/2 mx-2 flex items-center rounded-sm text-white font-semibold shadow-lg ${type === "success" ? "bg-green" : "bg-red-600"}`}>
                        <div className="py-3 px-4 flex items-center flex-1">
                            <Info className="w-8 h-8 mr-4" /> 
                            <span style={{ textShadow: '0px 0px 10px rgba(0, 0, 0, 0.6)' }}>{text}</span>
                        </div>
                        <button onClick={onClose} title="Fechar" className="mr-4 pt-1 font-bold hover:bg-black/25 transition-colors rounded-full w-10 h-10">X</button>
                    </div>
                </div>
            )}
        </>
        
    )
}

export default Notification
import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  xs?: string;
  color?: "lightGreen" | "darkGreen";
}

const Button = ({
  xs,
  color = "lightGreen",
  children,
  ...rest
}: ButtonProps) => {
  return (
    <button
      className={`bg-${color} rounded-md py-2 disabled:bg-gray-400 disabled:border disabled:border-black disabled:border-solid ${xs}`}
      style={{ textShadow: "0px 0px 5px rgb(0 0 0 / 30%)" }}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;

import { useEffect, useState } from 'react';

import { Anchor } from 'components';
import { Instagram, Youtube } from 'components/Icons';
import logoHorizontalPng from 'imagens/others/logo-horizontal176x60.png';
import logoHorizontalWebp from 'imagens/webp/logo-horizontal176x60.webp';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  const [hideContent, setHideContent] = useState(false);
  const [fullyHideContent, setFullyHideContent] = useState(false);

  useEffect(() => {
    const urlsToHide = ['/aula-gratuita/liberada', '/framework'];
    setHideContent(urlsToHide.includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    const urlsToFullyHide = ['/temp', '/temp2', '/temp3'];
    setFullyHideContent(urlsToFullyHide.includes(location.pathname));
  }, [location.pathname]);

  return (
    <footer
      className={`bg-black text-white pt-16 pb-8 ${
        fullyHideContent ? 'hidden' : ''
      }`}
    >
      <div className="container">
        <div
          className={`flex md:flex-row flex-col md:justify-between ${
            hideContent ? 'opacity-0' : ''
          }`}
        >
          <div className="md:basis-2/5 mb-4">
            <picture>
              <source srcSet={logoHorizontalWebp} type="image/webp" />
              <source srcSet={logoHorizontalPng} type="image/png" />
              <img
                className="md:w-36 w-44 mb-4"
                src={logoHorizontalPng}
                alt="logo da Karden completo"
                loading="lazy"
              />
            </picture>
            <p>
              Seja você aluno do plano anual, do plano mensal ou futuro aluno…
              estamos disponíveis para conversar sobre o Método Karden no botão
              abaixo. Você vai ser direcionado para o WhatsApp.
            </p>
          </div>
          <div className="md:basis-2/5">
            <Anchor
              href="https://api.whatsapp.com/send?phone=5517991903768&text=Ol%C3%A1%2C%20estou%20interessado%20na%20Escola%20Karden%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%B5es!"
              target="_blank"
              rel="noopener noreferrer"
              xs="px-8 text-black hover:text-white mb-8 md:ml-[50%] md:-translate-x-1/2"
            >
              <span className="text-xl">SUPORTE</span>
            </Anchor>
            <div className="flex md:justify-center">
              <a
                rel="noreferrer"
                target="_blank"
                className="mr-4"
                href="https://www.youtube.com/c/PedroBorotto"
              >
                <Youtube title="Icone do youtube" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/escolakarden/"
              >
                <Instagram title="Icone do instagram" />
              </a>
            </div>
          </div>
        </div>
        <h4 className="text-center md:mt-20 mt-10">
          Karden © {new Date().getFullYear()}. Todos direitos reservados.
        </h4>
        <h6 className="text-center text-sm">P V BOROTTO EDUCACAO</h6>
        <h6 className="text-center text-sm">CNPJ: 31.628.203/0001-50</h6>
      </div>
    </footer>
  );
};

export default Footer;

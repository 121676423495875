import { ApplyButton } from '../Subcomponents/ApplyButton';

type LandingSectionProps = {
  mainVideoHref: string;
};

export const LandingSection = ({ mainVideoHref }: LandingSectionProps) => (
  <section className="bg-black py-8 px-3 text-white flex flex-col justify-center items-center text-center">
    <div className="max-w-[960px] flex flex-col items-center pb-10 gap-2">
      <h2 className="uppercase text-xl md:text-3xl font-bold">
        <span className="text-green">de 15 mil a 100 mil mensais</span> com
        nossos pilares - sem depender <span className="lowercase">de</span>{' '}
        <span className="font-bold">gestor de tráfego ou conteúdo</span>
      </h2>
      <h2 className="text-sm md:text-lg max-w-[770px] pb-4">
        <span className="capitalize">
          Descubra Como Lojistas Estão{' '}
          <span className="font-bold">Multiplicando Suas Receitas</span>{' '}
          Aplicando{' '}
        </span>
        esses{' '}
        <span className="underline">Pilares de Escala nas suas operações.</span>
      </h2>
      <div className="relative w-full max-w-[900px] max-h-[500px] pt-[54%] overflow-hidden">
        <iframe
          src={mainVideoHref}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          className="w-full h-full top-0 left-0 absolute"
          title="original (1)"
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
    <div className="font-bold uppercase pt-8 pb-0 flex flex-col text-sm md:text-xl gap-1">
      <p>aplique para conquistar a sua vaga</p>
      <p className="text-green pb-2">apertando o botão abaixo</p>
      <ApplyButton />
    </div>
  </section>
);

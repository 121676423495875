import React from "react";
import logoWebp from "imagens/webp/logo56x56.webp";
import logoPng from "imagens/others/logo56x56.png";

const FreeClassVideo = () => {
  return (
    <section className="py-5 bg-black text-white">
      <div className="container">
        <div className="flex justify-end mb-4">
          <picture>
            <source srcSet={logoWebp} type="image/webp" />
            <source srcSet={logoWebp} type="image/png" />
            <img
              src={logoPng}
              className="w-8 h-8 mr-4"
              alt='Logo da escola Karden - uma letra "K" branca com uma pequena folha sobre ela'
            />
          </picture>
        </div>
        <h1 className="text-2xl font-bold mb-4 text-center text-green">
          conheça o método...
          <br />
          E-COMMERCE SEM "BURRICE"
        </h1>
        <h2 className="text-xl font-bold mb-4 lg:text-center">
          *Certifique-se que seu som está ligado.
        </h2>
        <div className="w-full px-0 xl:px-20 2xl:px-40">
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/831272508?h=c4d07d3896&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="original (1)"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>
    </section>
  );
};

export default FreeClassVideo;

import React, { createContext, useContext, useState } from 'react';
import Notification, { NotificationProps } from 'components/Generic/Notification'

type NotificationContextProps = {
    displayNotification: (message: string, type?:NotificationProps["type"]) => void
}
  
const NotificationContext = createContext<NotificationContextProps>({
    displayNotification: (message: string, type:NotificationProps["type"]="success") => {
        // default value.
    }
})
  
const NotificationProvider: React.FC = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [type, setType] = useState<NotificationProps["type"]>("success")

  
    const displayNotification = (
        message: string,
        newType: NotificationProps["type"] = 'success',
    ): void => {
        setMessage(message)
        setType(newType)
        setOpen(true)
    }
  
    return (
        <NotificationContext.Provider value={{ displayNotification }}>
            <Notification
                open={open}
                text={message}
                type={type}
                onClose={() => setOpen(false)}
            />
            {children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider

export function useNotification(): NotificationContextProps {
    return useContext(NotificationContext)
}
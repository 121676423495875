import React from 'react'

import warranty7daysPng from 'imagens/others/garantia-7-dias.png'
import warranty7daysWebp from 'imagens/webp/garantia-7-dias.webp'

const WarrantySection = () => {
    return (
        <section className='bg-black text-white text-center md:text-left'>
            <div className='container'>
                <div className='flex md:flex-nowrap flex-wrap py-16'>
                    <div className='md:basis-2/6 md:w-auto w-full flex lg:justify-end justify-center md:mb-0 mb-4'>
                        <picture>
                            <source srcSet={warranty7daysWebp} type="image/webp"/>
                            <source srcSet={warranty7daysPng} type="image/png"/> 
                            <img className='lg:w-52 w-44' src={warranty7daysPng} alt="Garantia de 7 dias" loading="lazy"/>
                        </picture>
                    </div>
                    <div className='md:basis-4/6 md:w-auto w-full flex items-center'>
                        <div className='mx-8'>
                            <h3 className='md:text-2xl text-xl tracking-widest mb-4'>TESTE POR 7 DIAS SEM COMPROMISSO</h3>
                            <hr className="w-32 h-0.5 bg-lightGreen text-lightGreen border-lightGreen mb-5 md:mx-0 mx-auto" />
                            <h5 className='md:text-xl sm:text-lg'>Aplique o Método Karden por uma semana inteira sem pagar nada.</h5>
                            <h5 className='md:text-xl sm:text-lg'>Que tal… agora?</h5>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WarrantySection
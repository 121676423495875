import React from 'react'
import Slider from './Slider'

const CarouselSection = () => {
    return (
        <section className='pt-12 pb-20 text-darkGreen text-center'>
            <div className='container'>
                <h2 className='lg:text-4xl sm:text-3xl text-2xl font-bold mb-4'>Plantando e acelerando Lojas Virtuais no Brasil</h2>
                <h5 className='md:text-xl text-base mb-10'>Veja abaixo os módulos que fazem parte do Método Karden</h5>
                <div className='sm:w-full w-11/12 mx-auto'>
                    <Slider />
                </div>
            </div>
        </section>
    )
}

export default CarouselSection
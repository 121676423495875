import React from "react";
import { LogoAnimated } from "components/Icons";

type LoadingProps = {
    open: boolean
}

const Loading = ({ open }: LoadingProps) => {
    return (
        <>
            {open && (<div className="w-full h-full fixed flex justify-center items-center bg-black/75 z-50">
                <LogoAnimated />
            </div>)}
        </>
    )
}

export default Loading;